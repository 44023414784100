import { Paper } from "@mui/material";
import React from "react";
import { useParams } from "react-router-dom";
import { PageLayout } from "../PageOrganiser/PageLayout";
import { RacesByUser } from "./RacesByUser";

export const RacesLayoutRouter: React.FC = () => {
  const { club, userid } = useParams();

  if (userid !== undefined && club !== undefined) {
    return (
      <div className="App">
        <PageLayout>
          <Paper elevation={3}>
            <RacesByUser
              userId={userid}
              club={club}
              hideStrava={true}
            ></RacesByUser>
          </Paper>
        </PageLayout>
      </div>
    );
  }

  return (
    <div>
      <h1>Strange Parameters Provided To User/Racer Router </h1>
      <ul>
        <li>Club [{club}]</li>
        <li>User [{userid}]</li>
      </ul>
    </div>
  );
};
