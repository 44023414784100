import {
  GridValueFormatterParams,
  GridColumns,
  GridRowsProp,
  DataGrid,
} from "@mui/x-data-grid";
import React from "react";

import { IStravaActivity } from "../../Models/IStravaActivity";
import { RacerNavigatorRow } from "../Racer/RacerNavigator";
import { ActivityToEventNavigator } from "./ActivityToEventNavigator";

export enum RaceGridColumn {
  StravaMobile = "StravaMobile",
  StravaBrowser = "StravaBrowser",
  Scraped = "Scraped",
  ScrapedForRunner = "ScrapedForRunner",
}

export interface IRaceGrid {
  races: IStravaActivity[];
  raceGridColumn: RaceGridColumn;
}

export const RaceGrid: React.FC<IRaceGrid> = (props) => {
  const widthForShortNumbers = 80;
  const widthForNumbers = 120;
  const widthForNumbersNarrow = widthForNumbers - 40;
  const widthForDate = 180;

  const ConvertNumber = (
    params: GridValueFormatterParams<number>,
    dp: number
  ) => {
    if (params.value == null) {
      return "";
    }

    const valueFormatted = Number(params.value)
      .toFixed(dp)
      .toLocaleString();
    return `${valueFormatted}`;
  };
  const toOneDp = (params: GridValueFormatterParams<number>) => {
    return ConvertNumber(params, 1);
  };
  const toNoDp = (params: GridValueFormatterParams<number>) => {
    return ConvertNumber(params, 0);
  };

  const scrapedColumns = React.useMemo<GridColumns<IStravaActivity>>(
    () => [
      {
        field: "year",
        headerName: "Year",
        width: widthForNumbersNarrow,
        align: "right",
        valueFormatter: toNoDp,
      },
      { field: "prettyDateId", headerName: "Date", width: 140 },
      { field: "name", headerName: "Name", width: 300 },
      {
        field: "distanceKm",
        headerName: "Dist (Km)",
        width: widthForNumbersNarrow,
        align: "right",
        valueFormatter: toOneDp,
      },
      {
        field: "distanceMiles",
        headerName: "Dist (Miles)",
        width: widthForNumbersNarrow,
        align: "right",
        valueFormatter: toOneDp,
      },
      {
        field: "position",
        headerName: "Position",
        width: widthForNumbersNarrow,
        align: "right",
        valueFormatter: toNoDp,
      },
      {
        field: "paceKm",
        headerName: "Pace (Km)",
        width: widthForNumbersNarrow,
        align: "right",
      },
      {
        field: "paceMiles",
        headerName: "Pace (Miles)",
        width: widthForNumbersNarrow,
        align: "right",
      },
      {
        field: "durationFriendly",
        headerName: "Duration",
        width: widthForNumbers,
      },
    ],
    [props.races]
  );

  const scrapedByRunnerColumns = React.useMemo<GridColumns<IStravaActivity>>(
    () => [
      { field: "year", headerName: "Year", width: widthForShortNumbers },
      {
        field: "runner",
        headerName: "Athlete",
        width: 200,
        valueFormatter: (params) => params.value.fullName,
        renderCell: RacerNavigatorRow,
      },
      {
        field: "durationFriendly",
        headerName: "Duration",
        width: widthForNumbers,
      },
      {
        field: "clubPosition",
        headerName: "Club Position",
        width: widthForNumbers,
        align: "right",
        valueFormatter: toNoDp,
      },
      {
        field: "position",
        headerName: "Position",
        width: widthForNumbers,
        align: "right",
        valueFormatter: toNoDp,
      },
      {
        field: "paceKm",
        headerName: "Pace (Km)",
        width: widthForNumbers,
        align: "right",
      },
      {
        field: "paceMiles",
        headerName: "Pace (Miles)",
        width: widthForNumbers,
        align: "right",
      },
    ],
    [props.races]
  );

  const browserColumns = React.useMemo<GridColumns<IStravaActivity>>(
    () => [
      { field: "year", headerName: "Year", width: widthForShortNumbers },
      { field: "prettyDateId", headerName: "Date", width: widthForDate },
      {
        field: "name",
        headerName: "Name",
        width: 300,
        valueFormatter: (params) => params.value.name,
        renderCell: ActivityToEventNavigator,
      },
      {
        field: "distanceKm",
        headerName: "Dist (Km)",
        width: widthForNumbers,
        align: "right",
        valueFormatter: toOneDp,
      },
      {
        field: "distanceMiles",
        headerName: "Dist (Miles)",
        width: widthForNumbers,
        align: "right",
        valueFormatter: toOneDp,
      },
      {
        field: "durationFriendly",
        headerName: "Duration",
        width: widthForNumbers,
      },
      {
        field: "totalElevationGain",
        headerName: "Elevation",
        width: widthForNumbers,
        align: "right",
        valueFormatter: toOneDp,
      },
      {
        field: "sufferScore",
        headerName: "Suffer Score",
        width: widthForNumbers,
        align: "right",
        valueFormatter: toOneDp,
      },
      {
        field: "kudosCount",
        headerName: "Kudos",
        width: widthForNumbers,
        align: "right",
        valueFormatter: toOneDp,
      },
      {
        field: "commentCount",
        headerName: "Comments",
        width: widthForNumbers,
        align: "right",
        valueFormatter: toOneDp,
      },
      {
        field: "totalPhotoCount",
        headerName: "Photos",
        width: widthForNumbers,
        align: "right",
        valueFormatter: toOneDp,
      },
      {
        field: "achievementCount",
        headerName: "Chevos",
        width: widthForNumbers,
        align: "right",
        valueFormatter: toOneDp,
      },
      {
        field: "prCount",
        headerName: "Personal Records",
        width: widthForNumbers,
        align: "right",
        valueFormatter: toOneDp,
      },
    ],
    [props.races]
  );

  const widthForMobileNumbers = 80;

  const mobileColumns = React.useMemo<GridColumns<IStravaActivity>>(
    () => [
      { field: "year", headerName: "Year", width: 90 },
      { field: "prettyDateId", headerName: "Date", width: widthForDate },
      { field: "name", headerName: "Name", width: 300 },
      {
        field: "distanceKm",
        headerName: "Km",
        width: widthForNumbers,
        align: "right",
        valueFormatter: toOneDp,
      },
      {
        field: "durationFriendly",
        headerName: "Duration",
        width: widthForNumbers,
      },
      {
        field: "sufferScore",
        headerName: "Suffer Score",
        width: widthForNumbers,
        align: "right",
        valueFormatter: toOneDp,
      },
      {
        field: "kudosCount",
        headerName: "Kudos",
        width: widthForNumbers,
        align: "right",
        valueFormatter: toOneDp,
      },
    ],
    [props.races]
  );

  const rows: GridRowsProp<IStravaActivity> = props.races;

  const uniqueRow = (row: IStravaActivity) => {
    return `${row.activityId}-${row.userGuid}`;
  };

  switch (props.raceGridColumn) {
    case RaceGridColumn.StravaMobile:
      return (
        <div>
          <div style={{ height: 400, width: "100%" }}>
            <DataGrid
              getRowId={(row) => row.activityId}
              rows={rows}
              columns={mobileColumns}
            />
          </div>
        </div>
      );
    case RaceGridColumn.StravaBrowser:
      return (
        <div>
          <div style={{ height: 1000, width: "100%" }}>
            <DataGrid
              getRowId={(row) => uniqueRow(row)}
              rows={rows}
              columns={browserColumns}
            />
          </div>
        </div>
      );
    case RaceGridColumn.Scraped:
      return (
        <div>
          <div style={{ height: 1000, width: "100%" }}>
            <DataGrid
              getRowId={(row) => uniqueRow(row)}
              rows={rows}
              columns={scrapedColumns}
            />
          </div>
        </div>
      );
    case RaceGridColumn.ScrapedForRunner:
      return (
        <div>
          <div style={{ height: 500, width: "100%" }}>
            <DataGrid
              getRowId={(row) => uniqueRow(row)}
              rows={rows}
              columns={scrapedByRunnerColumns}
            />
          </div>
        </div>
      );
  }
};
