import React from "react";
import { useParams, useSearchParams } from "react-router-dom";
import axios, { AxiosError } from "axios";
import { ConfigHelper } from "../../Config/ConfigHelper";
import { useAuth0 } from "@auth0/auth0-react";

import { toMyString } from "../Helpers/ToValue";
import { PageLayout } from "../PageOrganiser/PageLayout";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  List,
  ListItem,
  Typography,
} from "@mui/material";
import { useGlobalContext } from "../../Contexts/ClubContext";
import { Loading } from "../Feedback/Loading";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

interface StravaAuthResponse {
  token_type: string;
  expires_at: string;
  expires_in: string;
  refresh_token: string;
  access_token: string;
  athlete: StravaAthlete;
}

interface StravaAthlete {
  id: number;
  userName: string;
  firstName: string;
  lastName: string;
  city: string;
  state: string;
  country: string;
  profileMedium: string;
  sex: string;
  premium: boolean;
  createdAt: Date;
}

export const StravaRedirect: React.FC = () => {
  const { isLoading, isAuthenticated, error, user } = useAuth0();
  const { selectedClub } = useGlobalContext();

  if (isLoading) {
    return <div>Auth Zero Loading...</div>;
  }
  if (error) {
    return <div>Oops... {error.message}</div>;
  }

  const [searchParams] = useSearchParams();

  const code = searchParams.get("code");
  const scope = searchParams.get("scope");

  const { userid, userprovider } = useParams();

  const [apiCalled, setapiCalled] = React.useState<number>(0);

  const [stravaResponse, setStravaResponse] = React.useState<
    StravaAthlete | undefined
  >(undefined);

  const [stravaResponseCode, setStravaResponseCode] = React.useState<string>(
    "Empty"
  );

  const [solvedParameters, setSolvedParameters] = React.useState<string>();

  React.useEffect(() => {
    const fetchData = async () => {
      if (userid === undefined || userprovider === undefined || code === null) {
        setSolvedParameters(
          `Not All Parmaters Sovled ${userprovider} A.N.D ${userid} A.N.D ${code}`
        );
        return;
      }
      if (apiCalled === 0) {
        try {
          const url = `${new ConfigHelper().GetBaseUrl()}Strava/GetClaim/${selectedClub}/${userprovider}/${userid}/${code}`;

          setSolvedParameters(
            `All Parmaters Sovled ${userprovider} A.N.D ${userid} A.N.D ${code} to construct ${url}`
          );

          const response = await axios.post<StravaAthlete>(url);

          if (response.status === 200) {
            setStravaResponse(response.data);
          } else {
            setStravaResponseCode(response.statusText);
          }

          setapiCalled(apiCalled + 1);
        } catch (err) {
          console.log(err);
          setStravaResponseCode("Error Caught");
        }
      }
    };

    fetchData();
  }, [code, userid, userprovider]);

  if (stravaResponse === undefined) {
    return (
      <PageLayout>
        <Loading loadingMessage={`Connecting To Strava`} />
        <br></br>
        <div>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>
                <strong>Expand If Spinning</strong>
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <List>
                <ListItem>
                  Sent Strava this code=<b>{code}</b> and scope= <b>{scope}</b>
                </ListItem>
                <ListItem>
                  Solve Parameters<b>{solvedParameters}</b>
                </ListItem>
                <ListItem>
                  The claim Repsonse was <b>{stravaResponseCode}</b> because{" "}
                </ListItem>
                <ListItem>
                  Did Routing work? Provider=<b>{userprovider}</b> and
                  Identification=<b>{userid}</b>
                </ListItem>
              </List>
            </AccordionDetails>
          </Accordion>
        </div>
      </PageLayout>
    );
  } else {
    return (
      <PageLayout>
        <div className="content-layout">
          <h1 id="page-title" className="content__title">
            Connection To Strava Registered
          </h1>
          <div className="content__body">
            <p id="page-description">
              <span>
                You have linked your Strava so that you can share activities you
                have completed.
              </span>
              <span>This is the account that has been linked...</span>
            </p>
            <div className="profile__header">
              <div className="profile__headline">
                <img
                  src={stravaResponse.profileMedium}
                  alt="Profile"
                  className="profile__avatar"
                />
                <h2 className="profile__title">
                  {stravaResponse.firstName} {stravaResponse.lastName}
                </h2>
                <span className="profile__description">
                  With an athelete ID of {stravaResponse.id}
                </span>
              </div>
            </div>
          </div>
        </div>
      </PageLayout>
    );
  }
};
