import React from "react";
import { NavLink } from "react-router-dom";
import logo from "../../../assets/Logo-White-400x400.png";
import { Clubs, useGlobalContext } from "../../../Contexts/ClubContext";

export const NavBarBrand: React.FC = () => {
  const { selectedClub } = useGlobalContext();

  if (selectedClub === Clubs.HaywardsHeathHarriers) {
    return (
      <div className="nav-bar__brand">
        <NavLink to="/">
          <img
            className="nav-bar__logo"
            src={logo}
            alt={selectedClub + " Logo"}
            width="45"
            height="90"
          />
        </NavLink>
      </div>
    );
  }
  return <></>;
};
