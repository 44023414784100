import React from "react";
import { IRunner } from "../../Models/IRunner";
import PersonIcon from "@mui/icons-material/Person";
import { Avatar, ListItem, ListItemAvatar, ListItemText } from "@mui/material";
import { blue } from "@mui/material/colors";
import { IRacerListItem, IRunnerListItem } from "./IRunnerListItem";
import { IRacer } from "../../Models/IEvent";

export function RunnerListItem(props: IRunnerListItem) {
  const handleListItemClick = (value: IRunner) => {
    //Used to close the window but we probably want to drill down
    //onClose(value);
    props.handleRunnerClick(value);
  };

  return (
    <ListItem
      button
      onClick={() => handleListItemClick(props.runner)}
      key={props.runner.fullName}
    >
      <ListItemAvatar>
        <Avatar
          sx={{ bgcolor: blue[100], color: blue[600] }}
          src={props.runner.picture}
        >
          <PersonIcon />
        </Avatar>
      </ListItemAvatar>
      <ListItemText primary={props.runner.fullName} />
    </ListItem>
  );
}

export function RacerListItem(props: IRacerListItem) {
  const handleListItemClick = (value: IRacer) => {
    //Used to close the window but we probably want to drill down
    //onClose(value);
    props.handleRacerClick(value);
  };

  const listDescription = (racer: IRacer): string => {
    return `${racer.runner.fullName} - ${racer.duration} at ${racer.paceKm} (Suffered ${racer.sufferScore})`;
  };

  return (
    <ListItem
      button
      onClick={() => handleListItemClick(props.racer)}
      key={props.racer.userId}
    >
      <ListItemAvatar>
        <Avatar
          sx={{ bgcolor: blue[100], color: blue[600] }}
          src={props.racer.runner.picture}
        >
          <PersonIcon />
        </Avatar>
      </ListItemAvatar>
      <ListItemText primary={listDescription(props.racer)} />
    </ListItem>
  );
}
