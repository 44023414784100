import { createContext, useContext } from "react";

export type GlobalContent = {
  selectedClub: string;
  setSelectedClub: (c: string) => void;
};
export const ClubContext = createContext<GlobalContent>({
  selectedClub: "", // set a default value
  setSelectedClub: () => {},
});
export const useGlobalContext = () => useContext(ClubContext);

export enum Clubs {
  HaywardsHeathHarriers = "HHH",
  SundayRunningActually = "RBX",
}
