import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import React from "react";
import { ConfigHelper } from "../../Config/ConfigHelper";
import { useGlobalContext } from "../../Contexts/ClubContext";
import { toMyString } from "../Helpers/ToValue";
import { NavBarTab } from "../Navigation/Desktop/nav-bar-tab";
import { ConnectToStravaButton } from "./ConnectToStravaButton";

export interface IViewStravaConnectOrPages {
  supressConnectedAlreadyCheck: boolean;
}

export const ViewStravaConnectOrPages: React.FC<IViewStravaConnectOrPages> = (
  props
) => {
  const { user } = useAuth0();

  if (!user) {
    return null;
  }
  const [conectedToStrava, setConectedToStrava] = React.useState<boolean>();
  const { selectedClub } = useGlobalContext();

  if (props.supressConnectedAlreadyCheck) {
    setConectedToStrava(false);
  } else {
    React.useEffect(() => {
      const url = new ConfigHelper().GetIsUserConnectedToStravaUrl(
        selectedClub,
        toMyString(user.sub)
      );

      const fetchData = async () => {
        const response = await axios.get<boolean>(url);

        if (response.status === 200) {
          setConectedToStrava(response.data);
        } else {
          console.log(response.statusText);
        }
      };

      fetchData();
    }, []);
  }

  if (conectedToStrava === false) {
    <ConnectToStravaButton
      userId={toMyString(user.sub)}
    ></ConnectToStravaButton>;
  }

  return (
    <div className="nav-bar__tabs">
      <NavBarTab path="/StravaRaces" label="My Races" />
    </div>
  );
};
