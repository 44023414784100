import { useAuth0 } from "@auth0/auth0-react";
import React from "react";
import { LoginButton } from "../../../Buttons/login-button";
import { LogoutButton } from "../../../Buttons/logout-button";
import { AddEventButton } from "../../Event/AddEventButton";
import { ViewStravaConnectOrPages } from "../../Strava/ViewStravaConnectOrPages";

export const NavBarButtons: React.FC<{ showAddEvent: boolean }> = (props) => {
  const { isAuthenticated } = useAuth0();

  return (
    <div className="nav-bar__buttons">
      {!isAuthenticated && (
        <>
          <LoginButton />
        </>
      )}
      {isAuthenticated && (
        <>
          <ViewStravaConnectOrPages supressConnectedAlreadyCheck={false} />
          {/* <AddEventButton /> */}
          <LogoutButton />
        </>
      )}
    </div>
  );
};
