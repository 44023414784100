import React from "react";
import { Dialog, DialogTitle, Paper } from "@mui/material";
import { IStravaActivity } from "../../Models/IStravaActivity";
import { IDialogProps } from "../RunnersDialog/IDialogProps";
import { RaceGrid, RaceGridColumn } from "./RaceGrid";

export interface IRaceHistoryDialogProps extends IDialogProps<IStravaActivity> {
  races: IStravaActivity[];
  showInDialog: boolean;
}

export function RaceHistoryDialog(props: IRaceHistoryDialogProps) {
  const { onClose, open } = props;

  const handleClose = () => {
    onClose(undefined);
  };

  const handleEventUpdate = (value: IStravaActivity) => {
    onClose(value);
  };

  const handleListItemClick = (value: IStravaActivity) => {
    //Used to close the window but we probably want to drill down
    //onClose(value);
  };

  const content = (
    <Dialog onClose={handleClose} open={open} fullWidth={true} maxWidth="lg">
      <DialogTitle align="left">Races</DialogTitle>
      <RaceGrid races={props.races} raceGridColumn={RaceGridColumn.Scraped} />
    </Dialog>
  );

  if (props.showInDialog) {
    return content;
  }

  return <Paper elevation={3}>{content};</Paper>;
}
