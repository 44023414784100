import { Grid, Paper } from "@mui/material";
import React from "react";
import { useParams } from "react-router-dom";
import { IEvent } from "../../Models/IEvent";
import { IEventKey } from "../../Models/IEventRunners";
import { PageLayout } from "../PageOrganiser/PageLayout";
import { RacesByName } from "../Races/RacesByName";
import { RunnersDialog } from "../RunnersDialog/RunnersDialog";
import { EventDetails } from "./EventDetails";
import { IEventProps } from "./IEventProps";
import { useGlobalContext } from "../../Contexts/ClubContext";

export const EventRouter: React.FC = () => {
  const { clubEvent, dateid } = useParams();

  if (clubEvent !== undefined && dateid !== undefined) {
    return (
      <EventPage
        eventKey={{
          clubEvent: clubEvent,
          dateId: +dateid,
        }}
      ></EventPage>
    );
  }

  return (
    <div>
      <h1>Strange Parameters Provided</h1>
      <ul>
        <li>Club Event was {clubEvent}.</li>
        <li>Date was {dateid}.</li>
      </ul>
    </div>
  );
};

export const EventPage: React.FC<IEventProps> = (props) => {
  return (
    <div className="App">
      <PageLayout>
        <Event {...props}></Event>
      </PageLayout>
    </div>
  );
};

export const Event: React.FC<IEventProps> = (props) => {
  const [eventKey, setEventKey] = React.useState<IEventKey>(props.eventKey);

  const handleCloseOfViewRunners = (value?: IEvent) => {
    if (value) {
      setEventKey(value);
    }
  };

  return (
    <Grid container spacing={2} columns={10}>
      <Grid item xs={10}>
        <EventDetails eventKey={props.eventKey}></EventDetails>
      </Grid>
      <Grid item xs={10}>
        <Paper elevation={3}>
          <RacesByName clubEvent={props.eventKey.clubEvent} />
        </Paper>
      </Grid>
      <Grid item xs={10}>
        <RunnersDialog
          open={true}
          eventKey={eventKey}
          onClose={handleCloseOfViewRunners}
          showInDialog={false}
        />
      </Grid>
    </Grid>
  );
};
