import React from "react";
import axios from "axios";

import { IRunner, IRunnerKey } from "../../Models/IRunner";
import { ConfigHelper } from "../../Config/ConfigHelper";
import { Loading } from "../Feedback/Loading";

export interface IRacerBannerProps extends IRunnerKey {
  club: string;
}

export const RacerBanner: React.FC<IRacerBannerProps> = (props) => {
  const [racer, setRacer] = React.useState<IRunner>();
  const [apiError, setApiError] = React.useState<string>("");

  React.useEffect(() => {
    const url = new ConfigHelper().GetSingleUserUrl(props.club, props.userId);

    const fetchData = async () => {
      const response = await axios.get<IRunner>(url);

      if (response.status === 200) {
        setRacer(response.data);
      } else {
        setApiError(response.statusText);
      }
    };

    fetchData();
  }, []);

  if (racer) {
    return (
      <h1>
        Racer {racer.firstName} {racer.lastName}
      </h1>
    );
  }

  return <Loading loadingMessage="Loading Racer"></Loading>;
};
