import { User } from "@auth0/auth0-react";
import { toMyString } from "../Components/Helpers/ToValue";
import { IRunner } from "../Models/IRunner";

export const alreadySignedUp = (
  user: User | undefined,
  runners: IRunner[]
): boolean => {
  if (user === undefined || user === null) {
    return false;
  }

  if (runners === undefined || runners === null) {
    return false;
  }

  if (runners.length > 0) {
    const matches = runners.filter((x) => x.userId === toMyString(user.sub));
    return matches.length > 0;
  }

  return false;
};
