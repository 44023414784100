import { useAuth0 } from "@auth0/auth0-react";
import { Button } from "@mui/material";
import { isMobile } from "react-device-detect";
import React from "react";

export const LogoutButton: React.FC = () => {
  const { logout } = useAuth0();

  const handleLogout = () => {
    logout({
      returnTo: window.location.origin,
    });
  };

  return (
    <button
      className={isMobile ? "button__logout__tiny" : "button__logout"}
      onClick={handleLogout}
    >
      Log Out
    </button>
  );
};
