import React from "react";

export interface IPageLoader {
  message: string;
}

export const PageLoader: React.FC<IPageLoader> = (props) => {
  //const loadingImg = "https://cdn.auth0.com/blog/hello-auth0/loader.svg";
  const loadingImg = "../../assets/loading.svg";

  return (
    <div className="loader">
      <img src={loadingImg} alt={props.message} />
    </div>
  );
};
