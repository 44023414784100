import React from "react";
import { IRunner } from "../../Models/IRunner";
import { List, ListItem } from "@mui/material";
import { IRunnersDialogContent } from "./IRunnersDialogProps";
import { Participate } from "./Participate";
import { useAuth0 } from "@auth0/auth0-react";
import { alreadySignedUp } from "../../BusinessLogic/ParticipationChecker";
import { IEvent } from "../../Models/IEvent";
import { RunnersRoster } from "./RunnersRoster";

export function RunnersDialogContent(
  props: IRunnersDialogContent,
  handleListItemClick: (value: IRunner) => void,

  handleEventUpdate: (value: IEvent) => void
) {
  const { user } = useAuth0();

  return (
    <div>
      <RunnersRoster
        runners={props.confirmedRunners}
        groupName="Confirmed Runners"
        handleListItemClick={handleListItemClick}
      ></RunnersRoster>

      <RunnersRoster
        runners={props.interestedRunners}
        groupName="Interested Runners"
        handleListItemClick={handleListItemClick}
      ></RunnersRoster>

      <RunnersRoster
        runners={props.withdrawnRunners}
        groupName="Withdrawn "
        handleListItemClick={handleListItemClick}
      ></RunnersRoster>

      <List>
        <ListItem>
          <Participate
            key={props.eventKey.clubEvent + props.eventKey.dateId.toString()}
            eventKey={props.eventKey}
            userAlreadyConfirmed={alreadySignedUp(user, props.confirmedRunners)}
            userAlreadyInterested={alreadySignedUp(
              user,
              props.interestedRunners
            )}
            onUpdated={handleEventUpdate}
          />
        </ListItem>
      </List>
    </div>
  );
}
