import { FormControlLabel, FormGroup, Switch } from "@mui/material";
import React from "react";
import { IEventFilterProps } from "./IEventsProps";

export const EventFilter: React.FC<IEventFilterProps> = (props) => {
  return (
    <FormGroup>
      <FormControlLabel
        control={<Switch checked={props.onlyFutureEvents} />}
        label="Hide Past Events"
        onChange={props.onShowHistory}
      />
    </FormGroup>
  );
};
