import React from "react";
import { NavBarBrand } from "./nav-bar-brand";
import { NavBarButtons } from "./nav-bar-buttons";
import { NavBarTabs } from "./nav-bar-tabs";
import { NavBarTitle } from "./nav-bar-title";
import { isMobile } from "react-device-detect";

export const NavBar: React.FC = () => {
  if (isMobile) {
    return (
      <div className="nav-bar__container__tiny">
        <nav className="nav-bar">
          <NavBarTabs />
          <NavBarButtons showAddEvent={false} />
        </nav>
      </div>
    );
  }

  return (
    <div className="nav-bar__container">
      <nav className="nav-bar">
        <NavBarBrand />
        <NavBarTitle longTitle={true} />
        <NavBarTabs />
        <NavBarButtons showAddEvent={true} />
      </nav>
    </div>
  );
};
