import React from "react";
import { Paper } from "@mui/material";
import { EventFilter } from "./EventFilter";
import { Events } from "./Events";
import { IHomeProps } from "./IEventsProps";

export const EventViewer: React.FC<IHomeProps> = (props) => {
  //IEventsProps
  const [showHistoricEvents, setShowHistoricEvents] = React.useState(true);

  const onShowHistory = (event: React.ChangeEvent<HTMLInputElement>) => {
    setShowHistoricEvents(event.target.checked);
  };

  return (
    <Paper elevation={3}>
      <EventFilter
        {...props}
        onlyFutureEvents={showHistoricEvents}
        onShowHistory={onShowHistory}
      ></EventFilter>
      <Events {...props} onlyFutureEvents={showHistoricEvents}></Events>
    </Paper>
  );
};
