import {
  InputLabel,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import React from "react";
import { useGlobalContext } from "../../Contexts/ClubContext";
import { PageLayout } from "../PageOrganiser/PageLayout";
import { LeagueByClub } from "./League";

export const LeagueLayout: React.FC = () => {
  //props
  const { selectedClub } = useGlobalContext();

  const [year, setYear] = React.useState(2023);
  const handleYearChange = (event: SelectChangeEvent) => {
    setYear(+event.target.value);
  };

  return (
    <div className="App">
      <PageLayout>
        <Paper elevation={3}>
          <InputLabel id="demo-simple-select-label">Year</InputLabel>
          <Select
            label="Year"
            labelId="add-event-distance-label"
            id="demo-simple-select"
            value={`${year}`}
            disabled={false}
            onChange={handleYearChange}
          >
            <MenuItem value={2023}>2023</MenuItem>
            <MenuItem value={2022}>2022</MenuItem>
            <MenuItem value={2019}>2019</MenuItem>
          </Select>
          <LeagueByClub club={selectedClub} year={year}></LeagueByClub>
        </Paper>
      </PageLayout>
    </div>
  );
};
