import "./App.css";

import React, { useState } from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import {
  AppState,
  Auth0Provider,
  Auth0ProviderOptions,
  User,
} from "@auth0/auth0-react";

import { NotFoundPage } from "./Components/Feedback/NotFoundPage";
import { HomePage } from "./Components/PageOrganiser/HomePage";

import { Clubs } from "./Contexts/ClubContext";
import { ProfilePage } from "./Components/AuthZero/Profile";
import { ProtectedRoute } from "./Components/AuthZero/protected-route";
import { ErrorPage } from "./Components/ErrorPage/ErrorPage";
import { EventRouter } from "./Components/Event/Event";
import ClubRouter from "./ClubRouter";
import { toMyString } from "./Components/Helpers/ToValue";
import { ClubContext } from "./Contexts/ClubContext";
import { StravaRedirect } from "./Components/Strava/StravaRedirect";

import { LeagueLayout } from "./Components/League/LeagueLayout";
import { RacesLayout } from "./Components/Races/RacesLayout";
import { RacesLayoutRouter } from "./Components/Races/RacesLayoutRouter";
import {
  RacerHistoryRouter,
  RacerRouter,
} from "./Components/Racer/RacerRouter";

function App() {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <HomePage club={Clubs.HaywardsHeathHarriers} />,
      errorElement: <ErrorPage />,
    },
    {
      path: "historic/",
      element: <HomePage club={Clubs.HaywardsHeathHarriers} />,
      errorElement: <ErrorPage />,
    },
    //
    {
      path: "redirect/:exchange_token",
      element: <StravaRedirect />,
      errorElement: <ErrorPage />,
    },
    {
      path: "redirect/:userid/:exchange_token",
      element: <StravaRedirect />,
      errorElement: <ErrorPage />,
    },
    {
      path: "redirect/:userprovider/:userid/:exchange_token",
      element: <StravaRedirect />,
      errorElement: <ErrorPage />,
    },

    {
      path: "topracers/",
      element: <LeagueLayout />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/:club",
      element: <ClubRouter />,
      errorElement: <ErrorPage />,
    },
    {
      path: "club/:club",
      element: <ClubRouter />,
      errorElement: <ErrorPage />,
    },
    {
      // path: "event/:clubEvent/:dateid",
      path: "event/:clubEvent/:dateid",
      element: <EventRouter />,
    },
    {
      // path: "event/:clubEvent",
      path: "event/:clubEvent/",
      element: <RacerHistoryRouter />,
    },
    {
      path: "racer/:club/:userId",
      element: <RacerRouter />,
    },
    {
      path: "StravaRaces",
      element: <ProtectedRoute component={RacesLayout} />,
    },
    {
      path: "StravaRaces/:club/:userId",
      element: <RacesLayoutRouter />,
    },
    {
      path: "profile",
      element: <ProtectedRoute component={ProfilePage} />,
    },

    {
      path: "*",
      element: <NotFoundPage />,
      errorElement: <ErrorPage />,
    },
  ]);

  const [club, setClub] = useState<string>(Clubs.HaywardsHeathHarriers);

  const onRedirectCallback = (
    appState?: AppState | undefined,
    user?: User | undefined
  ): void | undefined => {
    // history.push(
    //   appState && appState.returnTo ? appState.returnTo : window.location.pathname
    // );
  };

  const providerConfig: Auth0ProviderOptions = {
    domain: toMyString(process.env.REACT_APP_AUTH0_DOMAIN),
    clientId: toMyString(process.env.REACT_APP_AUTH0_CLIENT_ID),
    //...(config.audience ? { audience: config.audience } : null),

    redirectUri: window.location.origin,
    onRedirectCallback: onRedirectCallback,
  };

  return (
    <div className="App">
      <Auth0Provider {...providerConfig}>
        <ClubContext.Provider
          value={{ selectedClub: club, setSelectedClub: setClub }}
        >
          <RouterProvider router={router} />;
        </ClubContext.Provider>
      </Auth0Provider>
    </div>
  );
}

export default App;
