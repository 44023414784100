import React from "react";

import { useAuth0 } from "@auth0/auth0-react";
import { toMyString } from "../Helpers/ToValue";
import { RacesByUser } from "./RacesByUser";

export const Races: React.FC = () => {
  const { user } = useAuth0();
  if (!user) {
    return <h4>Logon to view your race histroy </h4>;
  }

  return (
    <RacesByUser
      userId={toMyString(user.sub, "")}
      club={"HHH"}
      hideStrava={true}
    />
  );
};
