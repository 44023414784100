import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  List,
  ListItem,
} from "@mui/material";
import React from "react";
import { IRunner } from "../../Models/IRunner";
import { RunnerListItem } from "./RunnerListItem";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { IRacer } from "../../Models/IEvent";

export interface IRunnersRoster {
  runners: IRunner[];
  groupName: string;
  handleListItemClick: (value: IRunner) => void;
}

export function RunnersRoster(props: IRunnersRoster) {
  const NoRunners = (values: IRunner[] | IRacer[]): boolean => {
    return values === null || values === undefined || values.length === 0;
  };

  const runnerCount = (runners: IRunner[]): string => {
    return NoRunners(runners) ? "None" : runners.length.toString();
  };

  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        {props.groupName} ({runnerCount(props.runners)})
      </AccordionSummary>
      <AccordionDetails>
        <List sx={{ pt: 0 }}>
          {NoRunners(props.runners) ? (
            <ListItem key={props.groupName + "_none"}>
              No confirmed runners
            </ListItem>
          ) : (
            props.runners.map((runner) =>
              RunnerListItem({
                runner,
                handleRunnerClick: props.handleListItemClick,
              })
            )
          )}
        </List>
      </AccordionDetails>
    </Accordion>
  );
}
