import React from "react";
import { Clubs, useGlobalContext } from "../../../Contexts/ClubContext";

export const NavBarTitle: React.FC<{ longTitle: boolean }> = (props) => {
  const { selectedClub } = useGlobalContext();

  if (props.longTitle) {
    let longName = "Haywards Heath Harriers";
    if (selectedClub === Clubs.SundayRunningActually) {
      longName = "Sunday Running Actually";
    }

    return (
      <div className="nav-bar__brand">
        <h4>{longName} Events</h4>
      </div>
    );
  }

  return (
    <div className="nav-bar__brand">
      <h4>{selectedClub} Events</h4>
    </div>
  );
};
