import moment from "moment";
import { IEventKey } from "../Models/IEventRunners";
import { Participation } from "../Models/Particaption";
import { IRacesByUser } from "../Components/Races/RacesByUser";

export class ConfigHelper {
  GetEnvironement(): string {
    return process.env.NODE_ENV;
  }

  GetEventsUrl(club: string, onlyFutureEvents: boolean): string {
    let now = new Date();

    const dateString = moment(now).format("YYYYMMDD");
    const timeScope = onlyFutureEvents ? "future" : "past";
    //You don't need enriched because this gets full user details
    // enriched adds extra calls to the auth Zero which means rate limits hit
    return `${this.GetBaseUrl()}Event/${timeScope}/${encodeURIComponent(
      club
    )}/${dateString}`;
  }

  GetEventUrl(eventKey: IEventKey): string {
    const clubEventEncoded = encodeURIComponent(eventKey.clubEvent);

    return `${this.GetBaseUrl()}Event/Single/Enriched/${clubEventEncoded}/${
      eventKey.dateId
    }`;
  }

  private getParticaptionSubPath = (
    withdrawing: boolean,
    participation: Participation
  ): string => {
    if (withdrawing) {
      return "Withdrawn";
    }

    return participation.toString();
  };

  GetParticaptionUrl(
    event: IEventKey,
    user: string,
    withdrawing: boolean,
    participation: Participation
  ): string {
    const clubEncoded = encodeURIComponent(event.clubEvent);
    const userEncoded = encodeURIComponent(user);

    const subPath = this.getParticaptionSubPath(withdrawing, participation);

    return `${this.GetBaseUrl()}Participation/${subPath}/${clubEncoded}/${
      event.dateId
    }/${userEncoded}`;
  }

  GetBaseUrl(): string {
    switch (process.env.NODE_ENV) {
      case "development":
      case "test":
        return "https://localhost:7131/api/";
      default:
        return "https://eventdistributorapi-app-20221018.whiteriver-0c527329.westeurope.azurecontainerapps.io/api/";
    }
  }

  GetUserUrl(): string {
    switch (process.env.NODE_ENV) {
      case "development":
      case "test":
        return "https://localhost:7087/api/";
      default:
        return "https://userapi-app.whiteriver-0c527329.westeurope.azurecontainerapps.io/api/";
    }
  }

  GetRaceUrl(): string {
    switch (process.env.NODE_ENV) {
      case "development":
      case "test":
        return "https://localhost:7258/api/";
      default:
        return "https://raceapi-app.whiteriver-0c527329.westeurope.azurecontainerapps.io/api/";
    }
  }

  GetSingleUserUrl(club: string, user: string): string {
    const clubEncoded = encodeURIComponent(club);
    const userEncoded = encodeURIComponent(user);

    return `${this.GetUserUrl()}User/Get/${clubEncoded}/${userEncoded}/`;
  }

  GetIsUserConnectedToStravaUrl(club: string, user: string): string {
    const clubEncoded = encodeURIComponent(club);
    const userEncoded = encodeURIComponent(user);

    return `${this.GetUserUrl()}User/IsConnectedToStrava/${userEncoded}/${clubEncoded}`;
  }

  GetStravaRacesUrl(params: IRacesByUser): string {
    const clubEncoded = encodeURIComponent(params.club);
    const userEncoded = encodeURIComponent(params.userId);
    if (params.hideStrava) {
      return `${this.GetRaceUrl()}Races/Scraped/${userEncoded}/${clubEncoded}`;
    }
    return `${this.GetRaceUrl()}Races/Scraped/${userEncoded}/${clubEncoded}`;
  }

  GetStravaRacesByNameUrl(club: string, name: string): string {
    const clubEncoded = encodeURIComponent(club);
    const nameEncoded = encodeURIComponent(name);

    return `${this.GetRaceUrl()}Races/Club/ByName/${clubEncoded}/${nameEncoded}`;
  }

  GetTopRacersUrl(club: string, year: number): string {
    const clubEncoded = encodeURIComponent(club);
    const yearEncoded = encodeURIComponent(year);

    return `${this.GetRaceUrl()}Races/Club/${clubEncoded}/${yearEncoded}`;
  }

  GetStravaRedirectUrl(): string {
    switch (process.env.NODE_ENV) {
      case "development":
      case "test":
        return "http://localhost:3000";
      default:
        return "https://www.therunningcalendar.com";
    }
  }
}
