import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { ConfigHelper } from "../../Config/ConfigHelper";
import axios from "axios";
import { toMyString } from "../Helpers/ToValue";
import { IEvent } from "../../Models/IEvent";
import { Participation } from "../../Models/Particaption";
import { IParticipateProps } from "./IParticipateProps";

export function Participate(props: IParticipateProps) {
  const { user } = useAuth0();
  const [serviceResponse, setServiceResponse] = React.useState("");

  if (serviceResponse !== "") {
    return <h3>serviceResponse</h3>;
  }

  if (!user) {
    return <h4>Logon to confirm or register interest</h4>;
  }

  const onRegisterParticaption = async (
    participation: Participation,
    withdrawing: boolean
  ) => {
    const url = new ConfigHelper().GetParticaptionUrl(
      props.eventKey,
      toMyString(user.sub),
      withdrawing,
      participation
    );

    console.log(`Url -> ${url}`);

    const response = await axios.post<IEvent>(url);

    if (response.status === 200) {
      props.onUpdated(response.data);
    } else {
      setServiceResponse(
        `Oops could not particapte [${response.status}] - ${response.statusText}`
      );
    }
  };

  const confirmButton = (
    <button
      className="button__addEvent"
      onClick={() => {
        onRegisterParticaption(Participation.Confirmed, false);
      }}
    >
      I'm In!
    </button>
  );

  const interestedButton = (
    <button
      className="button__addEvent"
      onClick={() => {
        onRegisterParticaption(Participation.Interested, false);
      }}
    >
      I'm Tempted!
    </button>
  );

  const withdrawButton = (
    <button
      className="button__addEvent"
      onClick={() => {
        onRegisterParticaption(Participation.Confirmed, true);
      }}
    >
      I'm Withdrawing (sad times)
    </button>
  );

  if (props.userAlreadyConfirmed) {
    return (
      <div>
        <h4>{user.given_name} you are participating</h4>
        <div className="nav-bar__buttons">{withdrawButton}</div>
      </div>
    );
  }

  if (props.userAlreadyInterested) {
    return (
      <div>
        <h4>{user.given_name} you are confirming or withdrawing interest?</h4>
        <div className="nav-bar__buttons">
          {confirmButton}
          {withdrawButton}
        </div>
      </div>
    );
  }

  return (
    <div>
      <h4>{user.given_name} show your buddies you are interested</h4>
      <div className="nav-bar__buttons">
        {confirmButton}
        {interestedButton}
      </div>
    </div>
  );
}
