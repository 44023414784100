import React from "react";
import { Paper } from "@mui/material";

import { PageLayout } from "./PageLayout";
import { isMobile } from "react-device-detect";

import { EventViewer } from "../Events/EventViewer";
import { IHomeProps } from "../Events/IEventsProps";

export const HomePage: React.FC<IHomeProps> = (props) => {
  if (isMobile) {
    return (
      <div className="App">
        <PageLayout>
          <EventViewer {...props}></EventViewer>
        </PageLayout>
      </div>
    );
  }

  return (
    <div className="App">
      <PageLayout>
        <Paper elevation={3}>
          <EventViewer {...props}></EventViewer>
        </Paper>
      </PageLayout>
    </div>
  );
};
