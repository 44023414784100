import React from "react";

import { Paper } from "@mui/material";
import { PageLayout } from "../PageOrganiser/PageLayout";
import { Races } from "./Races";

export const RacesLayout: React.FC = () => {
  return (
    <div className="App">
      <PageLayout>
        <Paper elevation={3}>
          <Races></Races>
        </Paper>
      </PageLayout>
    </div>
  );
};
