import React from "react";

import { toMyString } from "../Helpers/ToValue";
import { ConfigHelper } from "../../Config/ConfigHelper";

import stravaConnect from "../../assets/btn_strava_connectwith_light.png";

export interface IConnectToStravaButton {
  userId: string;
}

export const ConnectToStravaButton: React.FC<IConnectToStravaButton> = (
  props
) => {
  const userSplit = toMyString(props.userId, "").split("|");
  const userProvider = userSplit[0];
  const userId = userSplit[1];

  const redirectUrl = `${new ConfigHelper().GetStravaRedirectUrl()}/redirect/${userProvider}/${userId}`;
  //const scope = "activity:read_all";
  const scope = "activity:read";
  //const approval_prompt = "force";
  const approval_prompt = "auto";

  const handleLogin = () => {
    //levelup.gitconnected.com/add-strava-oauth2-login-to-your-react-app-in-15-minutes-6c92e845919e
    const url = `http://www.strava.com/oauth/authorize?client_id=2149&response_type=code&redirect_uri=${redirectUrl}/exchange_token&approval_prompt=${approval_prompt}&scope=${scope}`;
    window.location.href = url;
  };

  return (
    <div>
      <button onClick={handleLogin}>
        <img src={stravaConnect} alt="Connect To Strava" />
      </button>
    </div>
  );
};
