import axios from "axios";
import React from "react";
import { isMobile } from "react-device-detect";
import { ConfigHelper } from "../../Config/ConfigHelper";

import { IStravaActivity } from "../../Models/IStravaActivity";
import { Loading } from "../Feedback/Loading";
import { ConnectToStravaButton } from "../Strava/ConnectToStravaButton";
import { RaceGrid, RaceGridColumn } from "./RaceGrid";
import { IRunnerKey } from "../../Models/IRunner";

export interface IRacesByUser extends IRunnerKey {
  hideStrava: boolean;
}

export const RacesByUser: React.FC<IRacesByUser> = (props) => {
  const [races, setRaces] = React.useState<IStravaActivity[]>();

  const [apiError, setApiError] = React.useState<string>("");

  React.useEffect(() => {
    const url = new ConfigHelper().GetStravaRacesUrl(props);

    const fetchData = async () => {
      const response = await axios.get<IStravaActivity[]>(url);

      if (response.status === 200) {
        setRaces(response.data);
      } else {
        setApiError(response.statusText);
      }
    };

    fetchData();
  }, []);

  if (races) {
    if (races.length === 0) {
      return (
        <div>
          <p id="page-description">
            <span>
              No races found <strong>yet</strong>.
            </span>
            <span>
              This can take a while if you have just linked your account
            </span>
            <span>
              If you have not linked your account yet click on this to start the
              process
            </span>
          </p>
          <ConnectToStravaButton userId={props.userId} />
        </div>
      );
    } else {
      return (
        <RaceGrid
          raceGridColumn={
            isMobile
              ? RaceGridColumn.StravaMobile
              : RaceGridColumn.StravaBrowser
          }
          races={races}
        ></RaceGrid>
      );
    }
  }

  if (apiError.length > 1) {
    return <div>Error Occured - {apiError}</div>;
  }

  return <Loading loadingMessage={`Loading Your Races`} />;
};
