import React from "react";
import { isMobile } from "react-device-detect";

export const PageFooter = () => {
  if (isMobile) {
    return (
      <span>This mobile application is brought to you by Greg Hilton</span>
    );
  }

  return (
    <footer className="page-footer">
      <div className="page-footer-grid">
        <div className="page-footer-grid__info">
          <div className="page-footer-info__message">
            <p className="page-footer-message__headline">
              <span>This application is brought to you by Greg Hilton</span>
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
};
