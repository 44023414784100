import React, { PropsWithChildren } from "react";
import { NavBar } from "../Navigation/Desktop/nav-bar";
import { PageFooter } from "./PageFooter";
import { isMobile } from "react-device-detect";

interface Props {
  children: React.ReactNode;
}
export const PageLayout: React.FC<PropsWithChildren<Props>> = ({
  children,
}) => {
  if (isMobile) {
    return (
      <div className="page-layout">
        <NavBar />
        <div className="page-layout__content">{children}</div>
        <PageFooter />
      </div>
    );
  }

  return (
    <div className="page-layout">
      <NavBar />
      <div className="page-layout__content">{children}</div>

      <PageFooter />
    </div>
  );
};
