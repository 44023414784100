import React from "react";
import { Link, useRouteError, isRouteErrorResponse } from "react-router-dom";

export interface IEventProps {
  name: string;
}

export interface IErrorResponse {
  status: number;
  statusText: string;
  data: string;
}

export const ErrorPage: React.FC = () => {
  const error = useRouteError();

  if (isRouteErrorResponse(error)) {
    return (
      <div>
        <div>
          <nav
            style={{
              borderBottom: "solid 1px",
              paddingBottom: "1rem",
            }}
          >
            <Link to="/">Events</Link>
          </nav>
        </div>
        <div>
          <h1>Oops!</h1>
          <p>Sorry, an unexpected routing error has occurred.</p>
          <ul>
            <li>{error.status}</li>
            <li>{error.statusText}</li>
            <li>{error.data}</li>
          </ul>
        </div>
      </div>
    );
  }

  return (
    <div>
      <h1>Oops!</h1>
      <p>Sorry, an unexpected error has occurred. Nothing </p>
      <i>Nothing todo with routing</i>
    </div>
  );
};
