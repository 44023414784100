import { useAuth0 } from "@auth0/auth0-react";
import { Button } from "@mui/material";
import React from "react";
import { isMobile } from "react-device-detect";

export const LoginButton: React.FC = () => {
  const { loginWithRedirect } = useAuth0();

  const handleLogin = async () => {
    await loginWithRedirect({
      prompt: "login",
      appState: {
        returnTo: "/profile",
      },
    });
  };

  return (
    <div>
      <button
        className={isMobile ? "button__login__tiny" : "button__login"}
        onClick={handleLogin}
      >
        Log In
      </button>
    </div>
  );
};
