import { Grid, Paper, Rating, Typography } from "@mui/material";
import axios from "axios";
import React from "react";
import DirectionsRunIcon from "@mui/icons-material/DirectionsRun";
import DirectionsRunBorderIcon from "@mui/icons-material/DirectionsRunOutlined";
import { styled } from "@mui/material/styles";
import { ConfigHelper } from "../../Config/ConfigHelper";
import { IEvent } from "../../Models/IEvent";
import { Loading } from "../Feedback/Loading";
import { datePopulated } from "../Helpers/DatePopulated";
import { IEventProps } from "./IEventProps";

export function EventDetails(props: IEventProps) {
  const [event, setEvent] = React.useState<IEvent>();

  React.useEffect(() => {
    if (props.eventKey !== undefined && datePopulated(props.eventKey.dateId)) {
      const url = new ConfigHelper().GetEventUrl(props.eventKey);

      const fetchData = async () => {
        const response = await axios.get<IEvent>(url);

        setEvent(response.data);
      };

      fetchData();
    }
  }, [props.eventKey]);

  if (event === undefined) {
    return <Loading loadingMessage="Loading Event Details"></Loading>;
  }

  const StyledRating = styled(Rating)({
    "& .MuiRating-iconFilled": {
      color: "green",
    },
    "& .MuiRating-iconHover": {
      color: "grey",
    },
  });

  const competitionText = (event: IEvent): string => {
    if (event === undefined || event.competitionGroup.length < 1) {
      return "";
    }

    return `This is part of ${event.competitionGroup} competition`;
  };

  const runnersText = (event: IEvent): string => {
    if (
      event === undefined ||
      event.interestedRunnersCount + event.confirmedRunnersCount < 1
    ) {
      return "";
    }

    if (event.confirmedRunnersCount === 0) {
      if (event.interestedRunnersCount > 1) {
        return `There are interested runners (${event.interestedRunnersCount})`;
      }
      return `There is an interested runner`;
    }

    if (event.interestedRunnersCount === 0) {
      if (event.confirmedRunnersCount > 1) {
        return `There are confirmed runners (${event.confirmedRunnersCount}) `;
      }
      return `There is a confirmed runner`;
    }

    return `There are both confirmed (${event.confirmedRunnersCount}) and interested runners  (${event.interestedRunnersCount}) `;
  };

  const racer = (event: IEvent): string => {
    if (event.racers === null || event.racers == undefined) {
      return "None";
    }

    let racerLsit = "";
    racerLsit = event.racers.length.toString();
    event.racers.forEach((x) => {
      racerLsit = racerLsit + " - " + x.userId;
    });
    return racerLsit;
  };
  return (
    <Paper elevation={3}>
      <Grid container spacing={2} columns={10}>
        <Grid item xs={10} direction="column" alignItems="flex-start">
          <h1>{event.event}</h1>
        </Grid>
        <Grid item xs={10} direction="column" alignItems="flex-start">
          This is a <b>{event.distanceKm} km</b> (or {event.distanceMiles} mile)
          distance starting in <b>{event.location}</b>
          {". "}
          {competitionText(event)}
        </Grid>

        <Grid
          item
          xs={10}
          direction="row"
          justifyContent="center"
          alignItems="flex-start"
        >
          <StyledRating
            name="customized-color"
            defaultValue={event.confirmedRunnersCount}
            getLabelText={(value: number) =>
              `${value} Runners${value !== 1 ? "s" : ""}`
            }
            precision={0.5}
            icon={<DirectionsRunIcon fontSize="inherit" />}
            emptyIcon={<DirectionsRunBorderIcon fontSize="inherit" />}
            max={event.confirmedRunnersCount + event.interestedRunnersCount}
            disabled={true}
          />
        </Grid>
        <Grid
          item
          xs={10}
          direction="row"
          justifyContent="center"
          alignItems="flex-start"
        >
          <Typography>{runnersText(event)}</Typography>
        </Grid>
      </Grid>
    </Paper>
  );
}
