import React from "react";
import { Paper } from "@mui/material";

import { useParams } from "react-router-dom";
import { PageLayout } from "../PageOrganiser/PageLayout";
import { RacesByUser } from "../Races/RacesByUser";
import { RacerBanner } from "./RacerBanner";
import { RacesByName, getRaceName } from "../Races/RacesByName";

export const RacerHistoryRouter: React.FC = () => {
  const { clubEvent } = useParams();

  if (clubEvent !== undefined) {
    return (
      <div className="App">
        <PageLayout>
          <Paper elevation={3}>
            <h1>Racer {getRaceName({ clubEvent: clubEvent })}</h1>
            <RacesByName clubEvent={clubEvent}></RacesByName>
          </Paper>
        </PageLayout>
      </div>
    );
  }

  return (
    <div>
      <h1>Strange Parameters Provided</h1>
      <ul>
        <li>Club Event [{clubEvent}].</li>
      </ul>
    </div>
  );
};

export const RacerRouter: React.FC = () => {
  const { club, userId } = useParams();

  if (userId !== undefined && club !== undefined) {
    return (
      <div className="App">
        <PageLayout>
          <Paper elevation={3}>
            <RacerBanner userId={userId} club={club}></RacerBanner>
            <RacesByUser
              userId={userId}
              club={club}
              hideStrava={false}
            ></RacesByUser>
          </Paper>
        </PageLayout>
      </div>
    );
  }

  return (
    <div>
      <h1>Strange Parameters Provided</h1>
      <ul>
        <li>Club [{club}].</li>
        <li>User [{userId}].</li>
      </ul>
    </div>
  );
};
