// causing error Module parse failed: Unexpected token (52:69)
// myUndefinedString ?? ""
// myUndefinedNumber ?? 0
export const toMyString = (
  value: string | undefined | null,
  defaultValue: string = ""
): string => {
  if (value === undefined || value === null) {
    return defaultValue;
  }
  return value;
};

export const toMyNumber = (
  value: number | undefined | null,
  defaultValue: number = 0
): number => {
  if (value === undefined || value === null) {
    return defaultValue;
  }
  return value;
};
