import "./App.css";

import React from "react";
import { useParams } from "react-router-dom";

import { HomePage } from "./Components/PageOrganiser/HomePage";

import { Clubs, useGlobalContext } from "./Contexts/ClubContext";

function ClubRouter() {
  const { club } = useParams();
  const { selectedClub, setSelectedClub } = useGlobalContext();

  // const noClubSelect = clubFromUrl === undefined && club !== "";

  if (club !== undefined) {
    let globalClub: string = Clubs.HaywardsHeathHarriers;
    switch (club.toUpperCase()) {
      case Clubs.HaywardsHeathHarriers.toUpperCase():
        globalClub = Clubs.HaywardsHeathHarriers;
        break;
      case Clubs.SundayRunningActually.toUpperCase():
        globalClub = Clubs.SundayRunningActually;
        break;
    }
    setSelectedClub(globalClub);

    return <HomePage club={globalClub} />;
  } else {
    return <HomePage club={Clubs.HaywardsHeathHarriers} />;
  }
}

export default ClubRouter;
